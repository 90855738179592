@import '~antd/dist/antd.css';
body {
  overflow-x: hidden;
}
body, p, .ant-btn, label, .ant-form label, .ant-radio-wrapper, .ant-card, .ant-checkbox-wrapper,
.ant-collapse, .ant-menu, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  font-size: 1.2rem;
}
.therapy p,
.therapy p span,
.treatmentList p,
.treatmentList p span,
.therapy li,
.therapy li span,
.treatmentList li,
.treatmentList li > span {
  font-size: 1.2rem !important;
  font-family: 'Lato', sans-serif !important;
  color: #333 !important;
  background-color: inherit !important;
}
.ant-card-head {
  font-size: 1.2rem;
}
body.font-medium {
  font-size: 20px;
}
body.font-large {
  font-size: 22px;
}
/* Font */
h4,
.ant-list-vertical .ant-list-item-meta-title {
  font-size: 1.4rem;
}
h3 {
  font-size: 1.6rem;
}
h2 {
  font-size: 1.8rem;
}
h1 {
  font-size: 2.0rem;
}
.decisionAid .ant-typography strong {
  font-size: 0.9em;
}
@media(max-width: 992px ){
  body, p, .ant-btn, label, .ant-form label, .ant-radio-wrapper, .ant-card, .ant-checkbox-wrapper,
  .ant-collapse, .ant-menu, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    font-size: 1.1rem;
  }
  .therapy p,
.therapy p span,
.treatmentList p,
.treatmentList p span,
.therapy li,
.therapy li span,
.treatmentList li,
.treatmentList li > span {
    font-size: 1.1rem !important;
  }
  h4,
  .ant-list-vertical .ant-list-item-meta-title {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.7rem;
  }
  h1 {
    font-size: 1.9rem;
  }
}
@media(max-width: 768px ){
  body, p, .ant-btn, label, .ant-form label, .ant-radio-wrapper, .ant-card, .ant-checkbox-wrapper,
.ant-collapse, .ant-menu, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  font-size: 1rem;
}
.therapy p,
.therapy p span,
.treatmentList p,
.treatmentList p span,
.therapy li,
.therapy li span,
.treatmentList li,
.treatmentList li > span {
  font-size: 1rem !important;
}
h4,
.ant-list-vertical .ant-list-item-meta-title {
  font-size: 1.2rem;
}
h3 {
  font-size: 1.4rem;
}
h2 {
  font-size: 1.6rem;
}
h1 {
  font-size: 1.8rem;
}
}
@media(max-width: 576px ){
  body, p, .ant-btn, label, .ant-form label, .ant-radio-wrapper, .ant-card, .ant-checkbox-wrapper,
.ant-collapse, .ant-menu, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  font-size: 1rem;
}
.therapy p,
.therapy p span,
.treatmentList p,
.treatmentList p span,
.therapy li,
.therapy li span,
.treatmentList li,
.treatmentList li > span {
  font-size: 1rem !important;
}

h4,
.ant-list-vertical .ant-list-item-meta-title {
  font-size: 1.1rem;
}
h3 {
  font-size: 1.2rem;
}
h2 {
  font-size: 1.4rem;
}
h1 {
  font-size: 1.6rem;
}
}

body, h1, h2, h3, h4, p {
  font-family: 'Lato', sans-serif;
  color: #333;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-wrap {
  white-space: pre-wrap;
}
.therapy a span {
  color: #1890ff !important;
}
.ant-list-item-meta-title > a {
  color: #1890ff;
}

.therapy .ant-card-meta-title {
  height: 26px;
}
.pointer {
  cursor: pointer;
}
// Margin and Padding
// $grid-breakpoints: ( 768px, 992px, 1200px );
// @each $breakpoint in map-keys($grid-breakpoints) {
//   @include media-breakpoint-up($breakpoint) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

//     @each $prop, $abbrev in (margin: m, padding: p) {
//       @each $size, $length in $spacers {

//         .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
//         .#{$abbrev}t#{$infix}-#{$size},
//         .#{$abbrev}y#{$infix}-#{$size} {
//           #{$prop}-top: $length !important;
//         }
//         .#{$abbrev}r#{$infix}-#{$size},
//         .#{$abbrev}x#{$infix}-#{$size} {
//           #{$prop}-right: $length !important;
//         }
//         .#{$abbrev}b#{$infix}-#{$size},
//         .#{$abbrev}y#{$infix}-#{$size} {
//           #{$prop}-bottom: $length !important;
//         }
//         .#{$abbrev}l#{$infix}-#{$size},
//         .#{$abbrev}x#{$infix}-#{$size} {
//           #{$prop}-left: $length !important;
//         }
//       }
//     }

//     // Some special margin utils
//     .m#{$infix}-auto { margin: auto !important; }
//     .mt#{$infix}-auto,
//     .my#{$infix}-auto {
//       margin-top: auto !important;
//     }
//     .mr#{$infix}-auto,
//     .mx#{$infix}-auto {
//       margin-right: auto !important;
//     }
//     .mb#{$infix}-auto,
//     .my#{$infix}-auto {
//       margin-bottom: auto !important;
//     }
//     .ml#{$infix}-auto,
//     .mx#{$infix}-auto {
//       margin-left: auto !important;
//     }
//   }
// }


/* footer {
  position: absolute;
  bottom: 0;
  width: 100%;
} */
$containers: ( 600: 600px, 800: 800px, 1000: 1000px, 1200: 1200px );
@each $key, $val in $containers {
  .container#{$key} {
    width: 100%;
    max-width: $val;
  }
}

.ant-collapse-item {
  background: #ededed;
}
.pageTitle {
  text-align: center;
}
footer {
  margin-top: 50px;
  background-color: #0D1726;
  color: #fff;
}
footer p,
footer a {
  color: #fff;
  font-size: 15px;
}
.center {
  margin: 0 auto;
}
.right {
  float: right;
}
.left {
  float: left;
}
.m-0 {
  margin: 0;
}
.mx-1 {
  margin: 0 1em;
}
.my-1 {
  margin: 1em 0;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2em;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 1em;
}
.mr-1 {
  margin-right: 1em;
}
.mr-2 {
  margin-right: 2em;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 2em;
}
@media(max-width: 1240px){
  .mb-md-1 {
    margin-bottom: 1em;
  }
}
.mb-2 {
  margin-bottom: 2em;
}
.ml-1 {
  margin-left: 1em;
}
.p-1 {
  padding: 1em;
}
.p-2 {
  padding: 2em;
}
.pb-1 {
  padding-bottom: 1em;
}
.px-1 {
  padding: 0 1em;
}
.py-1 {
  padding: 1em 0;
}
.pt-1 {
  padding-top: 1em;
}

.listNoStyle {
  list-style-type: none;
  padding: 0;
}

/* Layout */
.ant-layout {
  display: flex;
  flex-direction: column;
  flex: auto;
  background: #fff;
  min-height: 0;
}
.ant-row {
  position: relative;
  margin: 0 16px;
  height: auto;
  zoom: 1;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ant-checkbox-group-item {
  width: 100%;
}

.menuBar{
  padding: 0 20px;
  border-bottom: solid 1px #e8e8e8;
  overflow: auto;
  box-shadow: 0 0 30px #f3f1f1;
}
.logo {
  //width: 120px;
  width: 61px;
  height: 65px;
  padding-top: 3px;
  float: left;
}
.fullLogo {
  width: 100%;
  max-width: 600px;
}
.logo a{
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}
svg .gear {
  animation-name: ckw;
  animation-duration: 3s;
  -webkit-transform-origin: center center
}
svg .gearOn {
  animation-name: ckw;
  animation-duration: 3s;
  -webkit-transform-origin: center center
}
@keyframes ckw {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
svg .gearCircle {
  animation-name: cckw;
  animation-duration: 3s;
  -webkit-transform-origin: center center
}
svg .gearCircleOn {
  animation-name: cckw;
  animation-duration: 3s;
  -webkit-transform-origin: center center
}
@keyframes cckw {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(-360deg);
  }
}
.menuCon{
  width: calc(100% - 61px);
  float: left;
}
.menuCon .ant-menu-item{
  padding: 0px 5px;
}
.menuCon .ant-menu-submenu-title{
  padding: 10px 20px;
}
.menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a{
  padding: 10px 15px;
}
.menuCon .ant-menu-horizontal{
  border-bottom: none;
}
.menuCon .leftMenu{
  float: left;
}
.menuCon .rightMenu{
  float: right;
}
.barsMenu{
  float: right;
  //height: 32px;
  padding: 20px;
  display: none;
  background: none;
  border: none;
  //margin-top: 25px;
  
}
.barsMenu:hover {
  background-color: transparent;
}
.barsMenu.ant-btn-primary:active {
  background-color: transparent;
}
.barsBtn{
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}
.barsBtn:after,.barsBtn:before{
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}
.barsBtn:after{
  top: auto;
  bottom: -6px;
}
.ant-drawer-body{
  padding: 0;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item, .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu{
  display: inline-block;
  width: 100%;
}
.ant-drawer-body .ant-menu-horizontal{
  border-bottom: none;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover{
  border-bottom-color: transparent;
}

@media(max-width: 822px){
  .barsMenu{
    display: inline-block;
  }
  .leftMenu,.rightMenu{
    display: none;
  }
  .logo a{
    margin-left: -20px;
  }
  .menuCon .ant-menu-item, .menuCon .ant-menu-submenu-title{
    padding: 1px 20px;
  }
  .logo a{
    padding: 10px 20px;
  }
}

// List animation
.super-fly-list-component .measure {
  position: absolute;
  left: -9999px;
}
.super-fly-list-component {
  position: relative;
}
.super-fly-list-component li {
  position: absolute;
  transition: opacity .3s ease, transform .3s ease, top .1s ease;
}

/* ReactCSSTransitionGroup Animation styles*/
.superfly-enter {
  opacity: 0.01;
  transform: translateY(150px);
}
.superfly-enter.superfly-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition-delay: 0.25s; /* wait a bit for space to be made */
}
.superfly-leave {
  opacity: 1;
  transform: translateY(150px);
}
.superfly-leave.superfly-leave-active {
  opacity: 0.01;
  transform: translateY(100px);
}

.logo {
  float: left;
}
.logo svg {
  width: 100%;
}

.homeIconContainer {
  text-align: center;
}
.homeIcon {
  width: 50%;
  margin: 10px auto;
}
.decisionAid .borderedRow .homeIcon {
  max-height: 130px;
}

.heroTextContainer {
  position: absolute;
  height: 300px;
  top: 0;
  bottom: 0;
  margin: auto;
  padding-left: 32px;
  width: 100%;
}
@media(max-width: 600px) {
  .heroTextContainer{
    padding-left: 5px;
  }
}
.mainTitle {
  font-size: 40px;
  color: #327d54;
  max-width: 645px;
  position: absolute;
  left: 159px;
  top: -1px;
}
.mainSubTitle {
  font-weight: 400;
  font-size: 20px;
  display: block;
  border-top: 2px solid #19acde;
  text-transform: uppercase;
  margin-right: 5px;
}
.mainLogo {
  float: left;
  max-width: 120px;
  margin-right: 10px;
  margin-top: -32px;
}
@media(max-width: 840px){
  .mainTitle {
    font-size: 32px;
    left: 138px;
  }
  .mainSubTitle {
    font-weight: 400;
    font-size: 18px;
  }
  .mainLogo {
    max-width: 100px;
  }
  // .heroTextContainer {
  //   height: 250px;
  // }
}
@media(max-width: 700px){
  .mainTitle {
    font-size: 28px;
    left: 118px;
  }
  .mainSubTitle {
    font-weight: 400;
    font-size: 16px;
  }
  .mainLogo {
    max-width: 80px;
  }
  // .heroTextContainer {
  //   height: 230px;
  // }
}
@media(max-width: 600px){
  .mainTitle {
    font-size: 28px;
    display:block;
    position: relative;
    left: 0;
  }
  
  .mainLogo {
    max-width: 80px;
    display:block;
    position: relative;
    max-width: 100%;
    max-height: 100px;
  }
  .heroTextContainer {
    height: 300px;
  }
}
@media(max-width: 500px){
  .mainTitle {
    font-size: 22px;
    display:block;
    position: relative;
    left: 0;
  }
  .mainSubTitle {
    font-weight: 400;
    font-size: 14px;
  }
  .mainLogo {
    display:block;
    position: relative;
    max-width: 100%;
    max-height: 100px;
  }
  .heroTextContainer {
    height: 270px;
  }
}
.homeButton {
  background: none;
  font-size: 20px;
}

.therapy .ant-collapse-item {
  background: #fff;
}
.ant-rate-star svg {
  height: 51px;
  width: 40px;
  position: absolute;
  bottom: -3px;
}

.DemoContainer {
  text-align: right;
  margin: 15px 0;
}
.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 15px;
  text-align: center;
}

.Demo__some-network__share-count {
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width:32px;
  height:32px;
}

.numberCircle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 2px 3px 0 4px;
  background: #fff;
  border: 2px solid #1890ff;
  color: #1890ff;
  text-align: center;
  font: 24px Arial, sans-serif;
}

.verticalRadio {
  display: block;
  //height: 30px;
  white-space: normal;
  line-height: 30px;
}

.iconCard {
  margin-top: 1em;
}
.iconCard .ant-card-cover {
  padding: 1em 0.5em;
}
.iconCard .ant-card-body {
  //border-top: 1px solid #e8e8e8;
  padding: 0;
}
.iconCard img {
  height: 110px;
}

.rdw-editor-main {
  max-height: 500px;
  border: 1px solid #d9d9d9;
  padding: 5px;
  border-radius: 5px;
}
.rdw-plain-html {
  max-height: 100px;
  overflow-y: scroll;
  padding: 5px; 
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.overlay {
  width: 100%; 
  height: 100%;
  background: rgba(255,255,255,.5); 
  position: relative;
}

.ant-form-item-label {
  white-space: normal;
  display: inline-block;
  text-align: left;
  line-height: 20px;
  padding-right: 5px;
}
.ant-anchor-link {
    padding: 0;
    line-height: 1.143;
}
.ant-anchor-ink::before {
display:none;
}
.ant-anchor-wrapper {
    margin-left: 0;
    padding-left: 0;
    overflow: auto;
    background-color: transparent;
}

.ant-carousel .slick-slide {
  height: 300px;
  //line-height: 160px;
  //background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  //color: #fff;
}
dd { 
  display: block;
  margin-left: 40px;
}
.acknowledgement, .acknowledgement p {
  font-size: 14px;
}

.iconRow {
  .ant-checkbox-wrapper {
    width: 200px;
  }
  img {
    height: 30px; padding-right: 6px;
  }
  select {
    width: calc(100% - 200px); margin-top: 10px;
  }
}

.loadingSpinner {
  position: absolute;
  top: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,0.7);
}
.spinnerWrap {
  position: absolute;
  left: 45%;
  right: 0;
  margin: auto;
  top: 30%;
  bottom: 0;
}

.ant-tag, .ant-tag a, .ant-tag a:hover {
  color: #096dd9;
}

// ScrollBar
body::-webkit-scrollbar,
.ant-col ::-webkit-scrollbar {
  background-color:#fff;
  height:4px; 
  width: 4px;
}
body::-webkit-scrollbar-track,
.ant-col ::-webkit-scrollbar-track {
  background-color:#fff;
}
body::-webkit-scrollbar-thumb,
.ant-col ::-webkit-scrollbar-thumb {
  background-color:#9adfff;
  border-radius:4px;
}

.ant-popover {
  max-width: 400px;
}

.priorityItem {
  //background: green;
  //border: 1px solid green;
  border-left: 3px solid green;
  // border-top-left-radius: 4px;
  // border-bottom-left-radius: 4px;
  padding-left: 5px;
}

#stars {
  margin: auto;
  text-align: center;
}

.vertical {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.rate {
  text-align: center;
}
.div-btn {
  height: 100%;
  white-space: normal;
  display: flex;
    align-items: center;
    justify-content: center;
}
.homeInfoContainer {
  background: #b1d8ee;
  padding: .5em;
  height: 100%;
}
.homeInfoContainer p {
  margin-bottom: 0;
}
.homeInfo {
  padding: 10px 15px;
}
.border-white {
  border: 1px solid #fff;
}

.ant-btn-primary {
  background: #1a3955;
}
.borderedRow {
  border-bottom: 10px solid #1a3955;
  border-top: 10px solid #1a3955;
  background: #e5e2eb;
  padding-top: 10px;
}
.daInfoBorder {
  border-top: 2px solid #1a3955;
}

.ant-progress-inner {
  border: 1px solid #1a3955;
  margin-top: -1px;
}

.striped .ant-list-item:nth-child(odd) {
  background: #e5e2eb;
}

.treatmentSection {
  overflow: hidden;
}

.treatmentSection:nth-child(odd),
.treatmentSection:nth-child(odd) .ant-collapse .ant-collapse-item{
  background: #e5e2eb;
}

.treatmentList .ant-list-vertical .ant-list-item-action {
  margin-top: 0;
}

.treatmentList .ant-list-vertical .ant-list-item-meta {
  margin-bottom: 0;
}

.ant-list-item-meta-description {
  max-width: 800px;
}

.ant-back-top {
  right: 10px;
  bottom: 10px;
}
.result-red {
  border: 1px solid #d32f2f;
  border-top: 20px solid #d32f2f;
  border-radius: 0;
}
.result-yellow {
  border: 1px solid #ffeb3b;
  border-top: 20px solid #ffeb3b;
  border-radius: 0;
}
.result-green {
  border: 1px solid #7cc06a;
  border-top: 20px solid #7cc06a;
  border-radius: 0;
}

.daCard {
  background: #e1dfd7;
}

.bg-white {
  background: #fff;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}

.ant-collapse-item .ant-collapse-item-active {
  overflow: hidden;
}

.ant-card {
  line-height: 1.6;
}

.col-flex {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}
.col-flex-top {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: top;
}

footer p {
  margin-bottom: 0px;
}

.startHere {
  max-width: 170px;
  position: absolute;
  bottom: 121px;
  left: -146px;
  z-index: 8;
  display: block;
  //transform: rotate(15deg)
}
@media (max-width: 960px){
  .startHere {
    //display: none;
    bottom: -20px;
    right: 152px;
    left: auto;
    //left: 0;
    transform: rotate(-0deg)
  }
}

.therapyGroup {
  border: 1px solid #fff;
  border-radius: 4px;
}

.startOver {
  display: inline;
}
.startOverSm {
  display: none;
}
@media(max-width: 460px){
  .startOver {
    display: none;
  }
  .startOverSm {
    display: block;
  }
}

.matrixColTop {
  padding: 0 5px; 
  border-top:    2px solid  gray;
  border-right:  2px solid gray;
  border-left:   2px solid  gray;
}
.matrixCol {
  padding: 0 5px; 
  border-right:  2px solid gray;
  border-left:   2px solid  gray;
}

@media(max-width: 1240px){
  .matrixCol {
    padding: 5px; 
    border-top:    2px solid  gray;
    border-right:  2px solid gray;
    border-left:   2px solid  gray;
  }
  .matrixColTop {
    padding: 5px; 
  }
}
@media(max-width: 767px){

  .matrixCol,
  .matrixColTop {
    padding: 5px; 
    border:none;
  }
}

@media(min-width: 992px){
  .matrixColTop .matrixIcon,
  .matrixCol .matrixIcon {
    display:none;
  }
}

.ant-menu-horizontal li:nth-child(4)::before {
  content: '';
  height: 60%;
  width: 100%;
  border-left: 2px solid #1A3955;
  position: absolute;
  top: 15px;
  left: 0;
}

.ant-menu-horizontal li:nth-child(4)::after {
  content: '';
  height: 60%;
  width: 100%;
  border-right: 2px solid #1A3955;
  position: absolute;
  top: 15px;
  left: 0;
}

.ant-menu-item,
.ant-menu-submenu-title {
  text-transform: uppercase;
}

.tipsIntro {
  background: #e5e2eb;
  position: relative;
}
.tipIntroImgWrapper {
  max-width: 1300px;
  margin: 0 auto;
  padding-right: 300px;
}
.tipsIntroText {
  padding-top: 157px;
}
.abs {
  position: absolute;
  top: 0;
  width: 100%;
}

@media(max-width: 1150px ){
  .tipIntroImgWrapper {
    max-width: 100%;
    margin: 0 auto;
    padding-right: 100px;
  }
  .tipsIntroText {
    padding-top: 218px;
  }
}
@media(max-width: 1000px ){
  .tipsIntro .abs {
    position: relative;
    top: 0;
    width: 100%;
  }
  .tipsIntroText {
    padding-top: 10px;
  }
}

@media(max-width: 600px ){
  .tipIntroImgWrapper {
    max-width: 100%;
    margin: 0 auto;
    padding-right: 0;
  }
}

.tip {
  margin: 0 auto;
  max-width: 800px;
  position: relative;
}
.tip2 {
  border-top: solid 1px #4EAAD9;
  border-bottom: solid 1px #4EAAD9;
  padding: 1em 0 2em 0;
}
.number {
  width: 100px;
  position: absolute;
  left: -150px;
}
@media(max-width: 965px ){
  .number {
    left: -110px;
  }
}
@media(max-width: 766px ){
  .number {
    position: initial;
    float: none;
    width: 50px;
  }
  .stepFour .number {
    float: none;
  }

}
.injectIcons {
  padding: 0 2.5px 5px 2.5px;
}
.stepFour {
  border: 1px solid #263850;
  border-radius: 20px;
  padding: 0 10px 10px 10px; 
  word-break: break-word;

  .number {
    left: -40px;
    top: 30px
  }
}

.decisionAid img {
  max-height: 300px;
}

.treatmentEdu img {
  cursor: pointer;
}
.daResultWrapper {
  ul li.ant-list-item a:before,
  ul li.ant-list-item h4 div > span:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
  .darConsider{
    border-left: 30px solid #8ABE73;
    border-bottom: 2px solid #808080;
    ul li.ant-list-item a:before,
    ul li.ant-list-item h4 div > span:before {
      background: #8ABE73;
    }
  }
  .darDiscuss {
    border-left: 30px solid #FFFF00;
    border-bottom: 2px solid #808080;
    ul li.ant-list-item a:before,
    ul li.ant-list-item h4 div > span:before {
      background: #FFFF00;
    }
  }
  .darAvoid {
    border-left: 30px solid #C1272D;
    border-bottom: 2px solid #808080;
    ul li.ant-list-item a:before,
    ul li.ant-list-item h4 div > span:before {
      background: #C1272D;
    }
  }
}
.wrapper {
  width: 100%;
  max-width: 640px; //set your `max-width` here
  max-height: auto; //necessary to keep proportion
  margin: 0 auto; //centering 

}
.videoWrapper{
  width:100%;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
}

iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}